exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-baumer-accessories-js": () => import("./../../../src/pages/baumer-accessories.js" /* webpackChunkName: "component---src-pages-baumer-accessories-js" */),
  "component---src-pages-baumer-accessories-pressure-js": () => import("./../../../src/pages/baumer-accessories-pressure.js" /* webpackChunkName: "component---src-pages-baumer-accessories-pressure-js" */),
  "component---src-pages-baumer-diaphrame-seals-js": () => import("./../../../src/pages/baumer-diaphrame-seals.js" /* webpackChunkName: "component---src-pages-baumer-diaphrame-seals-js" */),
  "component---src-pages-baumer-differential-pressure-gauges-js": () => import("./../../../src/pages/baumer-differential-pressure-gauges.js" /* webpackChunkName: "component---src-pages-baumer-differential-pressure-gauges-js" */),
  "component---src-pages-baumer-temperature-switches-js": () => import("./../../../src/pages/baumer-temperature-switches.js" /* webpackChunkName: "component---src-pages-baumer-temperature-switches-js" */),
  "component---src-pages-brands-js": () => import("./../../../src/pages/brands.js" /* webpackChunkName: "component---src-pages-brands-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-achievements-js": () => import("./../../../src/pages/our-achievements.js" /* webpackChunkName: "component---src-pages-our-achievements-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-strainer-valve-manufacturer-india-js": () => import("./../../../src/pages/strainer-valve-manufacturer-india.js" /* webpackChunkName: "component---src-pages-strainer-valve-manufacturer-india-js" */),
  "component---src-pages-strainer-valves-supplier-bahrain-js": () => import("./../../../src/pages/strainer-valves-supplier-bahrain.js" /* webpackChunkName: "component---src-pages-strainer-valves-supplier-bahrain-js" */),
  "component---src-templates-industrial-valves-details-js": () => import("./../../../src/templates/industrial-valves-details.js" /* webpackChunkName: "component---src-templates-industrial-valves-details-js" */),
  "component---src-templates-industrialvalve-js": () => import("./../../../src/templates/industrialvalve.js" /* webpackChunkName: "component---src-templates-industrialvalve-js" */),
  "component---src-templates-instrument-detail-js": () => import("./../../../src/templates/instrument-detail.js" /* webpackChunkName: "component---src-templates-instrument-detail-js" */),
  "component---src-templates-instrument-js": () => import("./../../../src/templates/instrument.js" /* webpackChunkName: "component---src-templates-instrument-js" */)
}

